.protected-layout {
  padding-top: 10rem;
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .parts-subhead {
    width: 100%;
    margin-bottom: 1rem;

    p {
      padding: 0;
      margin-bottom: 0;
      margin-left: 0.2rem;
    }

    h1 {
      font-size: 4rem;
      letter-spacing: 0.3rem;
      padding: 0;
      margin: 0;
    }
  }
}
