.sign-up {
  .sign-up-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    button {
      min-width: 5rem;
      text-align: center;
    }
  }
}
