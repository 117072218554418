.home {
  .home-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .company-name {
      color: darken($primary, 50%);
      width: 100%;
      margin-top: 10vh;
      font-size: 6rem;
      span {
        vertical-align: top;
        font-size: 3rem;
      }
    }

    .parts-subhead {
      width: 100%;
      margin-bottom: 1rem;

      p {
        padding: 0;
        margin-bottom: 0;
      }

      h1 {
        font-size: 4rem;
        letter-spacing: 0.3rem;
        padding: 0;
        margin: 0;
      }
    }

    nav {
      width: 100%;
    }
  }
}
