.app {
  background-color: $gray-900;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 500px;
    margin: auto;
  }
}
