// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$heading-font-family: 'Lato', sans-serif;
$body-font-family: 'Source Sans Pro', sans-serif;
$font-family-sans-serif: $body-font-family !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
  monospace !default;
$font-family-base: $body-font-family !default;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: $spacer / 2 !default;
$headings-font-family: $heading-font-family !default;
$headings-font-weight: 700 !default;
$headings-line-height: 1.2 !default;
$headings-color: $gray-600 !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-small-font-size: $small-font-size !default;
$blockquote-font-size: $font-size-base * 1.25 !default;

$hr-border-color: rgba($black, 0.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;

$hr-margin-y: $spacer !default;

// Close

$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-color: $black !default;
$close-text-shadow: 0 1px 0 $white !default;

// Code

$code-font-size: 87.5% !default;
$code-color: $pink !default;

$kbd-padding-y: 0.2rem !default;
$kbd-padding-x: 0.4rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: $white !default;
$kbd-bg: $gray-900 !default;

$pre-color: $gray-900 !default;
$pre-scrollable-max-height: 340px !default;
