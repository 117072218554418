.search {
  width: 100%;
  input {
    box-shadow: $box-shadow-sm;

    &::placeholder {
      font-style: italic;
      font-weight: 200;
    }
  }

  .typeahead-results {
    background-color: white;
    width: 100%;
    box-shadow: $box-shadow-sm;
    border-radius: $border-radius;
    height: auto;
    max-height: 50vh;
    overflow-x: scroll;

    p {
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin: 0;
      cursor: pointer;
      padding: 10px;

      &:hover {
        color: $primary;
        background-color: $gray-100;
      }
    }
  }
}
