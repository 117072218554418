@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700|Source+Sans+Pro:600,700&display=swap');
@import './functions';
@import './variables/index.scss';

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// START: Add your code here
html,
body {
  margin: 0;
  width: 100%;
  height: 100vh;

  h2,
  button,
  a {
    text-transform: uppercase;
  }
}

.animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  &.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@import 'components/styles.scss';

@import '~bootstrap/scss/bootstrap';
