// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.3rem !default;

$table-color: $body-color !default;
$table-bg: null !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-color: $table-color !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-dark-color: $white !default;
$table-dark-bg: $gray-800 !default;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-color: $table-dark-color !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($table-dark-bg, 7.5%) !default;
$table-dark-color: $white !default;

$table-striped-order: odd !default;

$table-caption-color: $text-muted !default;

$table-bg-level: -9 !default;
$table-border-level: -6 !default;
