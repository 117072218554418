.layout {
  padding-top: 10rem;
  min-height: 100vh;

  .parts-subhead {
    width: 100%;
    margin-bottom: 1rem;

    p {
      padding: 0;
      margin-bottom: 0;
      margin-left: 0.2rem;
    }

    h1 {
      font-size: 4rem;
      letter-spacing: 0.3rem;
      padding: 0;
      margin: 0;
    }
  }

  .commonCard {
    width: 100%;

    .card-title {
      display: flex;
      justify-content: space-between;
    }

    .card {
      border-top: 0.25rem solid $primary;
      border-radius: 0.5rem;
    }
  }

  .small {
    font-weight: 700;
  }
}
